@import "~@/styles/variables/variables.scss";





































































































































































































































































































































































































































































.page-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	.content {
		flex: 1;
		height: 0;
		display: flex;
		.left {
			padding: 20px 12px;
			width: 300px;
			border-right: 1px solid #f1f1f1;
			height: 100%;
			overflow-y: scroll;
		}
		.right {
			flex: 1;
			width: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding: 20px 12px 6px;
			.search-modal {
				display: flex;
				align-items: center;
				.search {
					margin-right: 12px;
				}
			}
			.data-show-content {
				flex: 1;
				height: 0;
				position: relative;
				.echart-modal {
					position: absolute;
					top: 16px;
					left: 0;
					bottom: 20px;
					right: 0;
					z-index: 9;
					&.hide {
						opacity: 0;
						z-index: 0;
						pointer-events: none;
					}
				}
				.table-modal {
					border-radius: 8px 8px 0 0;
					display: flex;
					flex-direction: column;
					position: absolute;
					top: 16px;
					left: 0;
					bottom: 0;
					right: 0;
					z-index: 9;
					&.hide {
						opacity: 0;
						z-index: 0;
						pointer-events: none;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.page-layout {
		.content {
			.left {
				width: 280px;
			}
			.right {
				.data-show-content {
					.echart-modal {
						height: 240px;
						margin-top: 12px;
					}
				}
			}
		}
	}
}
